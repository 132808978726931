/* eslint-disable react/prop-types */
import { Container, Typography, CardContent, Card } from '@mui/material';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import HealingIcon from '@mui/icons-material/Healing';
import Grid from '@mui/material/Grid';
import React from 'react';

export const Services = ({ lan }) => {
	const myStyle = {
		paddingTop: '6vh',
		paddingBottom: '6vh',
	};

	const services = [
		{
			icon: 'seringa',
			text: 'Administrarea medicamentelor în mod intravenos',
			textEn: 'Intravenous medication administration',
		},
		{
			icon: 'seringa',
			text: 'Administrarea medicamentelor în mod intradermic',
			textEn: 'Intradermal medication administration',
		},
		{
			icon: 'seringa',
			text: 'Administrarea medicamentelor în mod intramuscular',
			textEn: 'Intramuscular medication administration',
		},
		{
			icon: 'seringa',
			text: 'Administrarea medicamentelor prin perfuzie intravenoasă',
			textEn: 'Intravenous infusion medication administration',
		},
		{
			icon: 'pansament',
			text: 'Îngrijirea plăgilor',
			textEn: 'Wound care',
		},
		{
			icon: 'pansament',
			text: 'Îngrijirea escarelor',
			textEn: 'Bedsore care',
		},
		{
			icon: 'pansament',
			text: 'Schimbarea pansamentelor',
			textEn: 'Bandage changes',
		},
		{
			icon: 'pansament',
			text: 'Suprimarea firelor de sutură',
			textEn: 'Suture removal',
		},
		{
			icon: 'pansament',
			text: 'Înlocuirea sondelor urinare',
			textEn: 'Urinary catheter replacement',
		},
	];

	return (
		<section style={myStyle} id='services'>
			<div
				style={{
					marginTop: '5vh',
					padding: '5vh',
					textAlign: 'center',
				}}
			>
				<Typography variant='h3' fontWeight={900} color={'secondary.main'}>
					{lan === 'Ro' ? 'Serviciile noastre' : 'Our services'}
				</Typography>
			</div>

			<div
				style={{
					padding: 2,
				}}
			>
				<Container maxWidth={'xl'} sx={{ textAlign: 'center' }}>
					<Typography>
						{lan === 'Ro'
							? `Tratamentele injectabile se efectuează DOAR pe baza recomandărilor
						prescrise de către medicul de familie și/sau medicul specialist!`
							: `Injectable treatments are provided ONLY with prescriptions from your doctor and/or specialist!`}
					</Typography>
					<Typography>
						{lan === 'Ro'
							? `Colaborăm cu Casa de Asigurări de Sănătate (CAS)`
							: ``}
					</Typography>
					<Grid container spacing={2} sx={{ mt: 2 }}>
						{services.map((service) => (
							<Grid item xs={12} sm={4} key={service.text}>
								<Card
									sx={{ width: '100%', height: '100%', bgcolor: '#C1C1C160' }}
								>
									<CardContent
										sx={{
											height: '100%',
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											justifyContent: 'center',
											textAlign: 'center',
										}}
									>
										{service.icon === 'pansament' ? (
											<HealingIcon
												sx={{ fill: '#f47842', height: '35px', width: '35px' }}
											/>
										) : (
											<VaccinesIcon
												sx={{ fill: '#1FA6D4', height: '35px', width: '35px' }}
											/>
										)}

										<Typography color='primary.main' mt={2}>
											{lan === 'Ro' ? service.text : service.textEn}
										</Typography>
										<br />
									</CardContent>
								</Card>
							</Grid>
						))}
					</Grid>
				</Container>
			</div>
		</section>
	);
};
