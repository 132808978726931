/* eslint-disable react/prop-types */
import { Typography, Link, Grid, Card, CardContent, Box } from '@mui/material';
import background from './home.png';
import React from 'react';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import SchoolIcon from '@mui/icons-material/School';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PeopleIcon from '@mui/icons-material/People';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

export const Home = ({ lan, mediumWidth, smallWidth }) => {
	const myStyle = {
		backgroundImage: `url(${background})`,
		height: !smallWidth && !mediumWidth && '75vh',
		paddingBottom: (smallWidth || mediumWidth) && '5vh',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		width: '100%',
	};

	const handleCardSize = () => {
		if (!mediumWidth && !smallWidth)
			return { width: '400px', minHeight: '300px' };
		else if (mediumWidth) return { maxWidth: '300px', minHeight: '300px' };
		else if (smallWidth) return { maxWidth: '350px' };
	};

	const handleGrid = () => {
		if (!mediumWidth && !smallWidth) return 8;
		else if (mediumWidth) return 4;
	};

	return (
		<section id='home'>
			<section style={myStyle}>
				{!smallWidth && <br />}
				<br />
				<div
					style={{
						paddingTop: '15vh',
						paddingLeft: '5vh',
						paddingRight: '5vh',
						marginBottom: '5vh',
					}}
				>
					<div
						style={{
							textAlign: 'left',
						}}
					>
						<Typography variant='h2'>
							{lan === 'Ro' ? 'Bine ați venit!' : 'Welcome!'}
						</Typography>
						<Typography variant='h4' color={'primary.light'}>
							{lan === 'Ro'
								? 'Asistență medicală calificată'
								: 'Qualified medical assistance'}
						</Typography>
					</div>
					<div
						style={{
							textAlign: 'left',
							marginTop: '5vh',
						}}
					>
						<Typography
							variant={'h5'}
							color='primary.light'
							sx={{ maxWidth: '900px' }}
						>
							{lan === 'Ro'
								? `Aveți de efectuat tratamentul prescris de către medic și nu știți
							cui să vă adresați? Cabinetul nostru pune la dispoziție`
								: `Do you need to follow the treatment prescribed by your doctor but don't know where to go? Our clinic offers`}{' '}
							<Link
								sx={{ cursor: 'pointer' }}
								color='primary.light'
								href='#services'
							>
								{lan === 'Ro' ? 'servicii medicale' : 'medical services'}
							</Link>{' '}
							{lan === 'Ro'
								? `adaptate nevoilor dumneavoastră, atât la domiciliu cât și la sediul nostru, inclusiv servicii acoperite prin Casa de Asigurări de Sănătate`
								: `tailored to your needs, either at home or at our office, including services covered by the national health insurance`}
							.
						</Typography>
					</div>
				</div>
			</section>
			<div
				style={{
					marginTop: !smallWidth && !mediumWidth ? '-17vh' : '-5vh',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					width: '100%',
				}}
			>
				<div
					style={{
						marginLeft: (mediumWidth || smallWidth) && '5vh',
						marginRight: (mediumWidth || smallWidth) && '5vh',
					}}
				>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6} md={handleGrid()} lg={4}>
							<Card sx={handleCardSize()}>
								<CardContent sx={{ padding: 5, display: 'block' }}>
									<Typography
										variant='h5'
										color={'secondary.main'}
										fontWeight={700}
										textAlign={'center'}
									>
										Camelia Căpitănescu
									</Typography>
									<br />
									<Box display={'flex'}>
										<LocalHospitalIcon sx={{ pt: 0.5, mr: 2 }} color={'info'} />
										<Typography>
											{lan === 'Ro'
												? 'Asistent medical generalist licențiat cu grad principal'
												: 'Licensed general nurse with senior rank'}
										</Typography>
									</Box>
									<br />
									<Box display={'flex'}>
										<SchoolIcon sx={{ pt: 0.5, mr: 2 }} color={'info'} />
										<Typography>
											{lan === 'Ro'
												? 'Profesor la Școala Postliceală Sanitară'
												: 'Professor at the Health Post-Secondary School'}
										</Typography>
									</Box>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={12} sm={6} md={handleGrid()} lg={4}>
							<Card sx={handleCardSize()}>
								<CardContent
									sx={{
										p: 5,
										display: 'block',
									}}
								>
									<Typography
										color={'secondary.main'}
										fontWeight={700}
										variant='h5'
										textAlign={'center'}
									>
										{lan === 'Ro' ? 'De ce să ne alegi' : 'Why choose us'}?
									</Typography>
									<br />
									<Box display={'flex'}>
										<AutoStoriesIcon color='info' sx={{ pt: 0.5, mr: 2 }} />
										<Typography>
											{lan === 'Ro'
												? 'Experiență de peste 20 ani'
												: 'Over 20 years of experience'}
										</Typography>
									</Box>
									<br />
									<Box display={'flex'}>
										<BorderColorIcon color='info' sx={{ pt: 0.5, mr: 2 }} />
										<Typography>
											{lan === 'Ro'
												? 'Seriozitate și promptitudine'
												: 'Professionalism and promptness'}
										</Typography>
									</Box>
									<br />
									<Box display={'flex'}>
										<PeopleIcon color='info' sx={{ pt: 0.5, mr: 2 }} />
										<Typography>
											{lan === 'Ro'
												? 'Personal dedicat și bine pregătit'
												: 'Dedicated, well-trained staff'}
										</Typography>
									</Box>
									<br />
									<Box display={'flex'}>
										<FormatListNumberedIcon
											color='info'
											sx={{ pt: 0.5, mr: 2 }}
										/>
										<Typography>
											{lan === 'Ro'
												? 'Servicii personalizate'
												: 'Personalized services'}
										</Typography>
									</Box>
									<br />
									<Box display={'flex'}>
										<AccessTimeIcon color='info' sx={{ pt: 0.5, mr: 2 }} />
										<Typography>
											{lan === 'Ro' ? 'Program flexibil' : 'Flexible schedule'}
										</Typography>
									</Box>
								</CardContent>
							</Card>
						</Grid>
						<Grid item xs={12} sm={6} md={handleGrid()} lg={4}>
							<Card sx={handleCardSize()}>
								<CardContent sx={{ padding: 5 }}>
									<Typography
										color={'secondary.main'}
										fontWeight={700}
										variant='h5'
										textAlign={'center'}
									>
										{lan === 'Ro' ? 'Unde ne găsești?' : 'Where to find us?'}
									</Typography>
									<br />
									<Box display={'flex'}>
										<FmdGoodIcon color='info' sx={{ pt: 0.5, mr: 2 }} />
										<Typography>
											Drobeta Turnu Severin, str. Crișan nr. 82C
										</Typography>
									</Box>
									<br />
									<Box display={'flex'}>
										<LocalPhoneIcon color='info' sx={{ pt: 0.5, mr: 2 }} />
										<Typography>+40 741 121 649</Typography>
									</Box>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</div>
			</div>
		</section>
	);
};
